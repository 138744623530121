import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ColorsDemo, OpacityDemo } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "цвета"
    }}>{`Цвета`}</h1>
    <p>{`Цвет — это инструмент управления вниманием пользователя: он помогает выделять
главное и передавать смысл.`}</p>
    <p>{`Для каждого цвета дизайн-системы ниже приведены имя соответствующей SCSS-переменной
и его RGB и HEX значения.`}</p>
    <h2 {...{
      "id": "прозрачность"
    }}>{`Прозрачность`}</h2>
    <p>{`Цвета можно комбинировать с различными уровнями прозрачности в соответствии с дизайном`}</p>
    <OpacityDemo mdxType="OpacityDemo" />
    <h2 {...{
      "id": "base"
    }}>{`Base`}</h2>
    <ColorsDemo palette="base" mdxType="ColorsDemo" />
    <h2 {...{
      "id": "brand"
    }}>{`Brand`}</h2>
    <p>{`Оттенки синего цвета созданные на базе фирменного цвета НИУ ВШЭ. Используются на носителях фирменного стиля и активных элементах в дизайн-системе.`}</p>
    <ColorsDemo palette="brand" mdxType="ColorsDemo" />
    <h2 {...{
      "id": "background"
    }}>{`Background`}</h2>
    <ColorsDemo palette="background" mdxType="ColorsDemo" />
    <h2 {...{
      "id": "еlement-overlay"
    }}>{`Еlement Overlay`}</h2>
    <p>{`Цвета, которые используются для осветления и затемнения элементов. С помощью них создаются новые состояния элементов.`}</p>
    <ColorsDemo palette="ElementOverlay" mdxType="ColorsDemo" />
    <h2 {...{
      "id": "screen-overlay"
    }}>{`Screen Overlay`}</h2>
    <p>{`Цвета осветления или затемнения экрана при появлении модального окна.`}</p>
    <ColorsDemo palette="ScreenOverlay" mdxType="ColorsDemo" />
    <h2 {...{
      "id": "system"
    }}>{`System`}</h2>
    <p>{`Цвета системных ошибок, предупреждений, положительных статусов.`}</p>
    <ColorsDemo palette="system" mdxType="ColorsDemo" />
    <h2 {...{
      "id": "gray"
    }}>{`Gray`}</h2>
    <p>{`Серые цвета. Помогают создавать разные приоритеты элементов.`}</p>
    <ColorsDemo palette="gray" mdxType="ColorsDemo" />
    <h2 {...{
      "id": "accent"
    }}>{`Accent`}</h2>
    <p>{`Для свежих акцентов в интерфейсе или для разделения контента на группы можно использовать дополнительную палитру.`}</p>
    <p>{`Рекомендуем использовать разные оттенки одного цвета для создания глубины.`}</p>
    <p>{`Главное правило сочетания дополнительных и основных цветов — не допускать нахождения рядом похожих.`}</p>
    <ColorsDemo palette="accent" mdxType="ColorsDemo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      